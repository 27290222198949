.header {
  position: relative;
}

.logo {
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 80px;

  a {
    display: block;

    img {
      width: 100%;
    }
  }

  @media (max-width: 1200px) {
    max-width: 290px;
    top: 50px;
    left: 70px;
  }
  @media (max-width: 992px) {
    max-width: 220px;
    top: 40px;
    left: 60px;
  }
  @media (max-width: 768px) {
    max-width: 150px;
    top: 30px;
    left: 50px;
  }
  @media (max-width: 576px) {
    max-width: 100px;
    top: 10px;
    left: 50px;
  }
}

.title {
  text-transform: uppercase;
  font-size: 92px;
  position: absolute;
  z-index: 1;
  left: 80px;
  bottom: 60px;
  color: white;
  max-width: 550px;
  @media (max-width: 1200px) {
    font-size: 72px;
    left: 70px;
    bottom: 50px;
  }
  @media (max-width: 992px) {
    font-size: 60px;
    left: 60px;
    bottom: 40px;
  }
  @media (max-width: 768px) {
    font-size: 48px;
    max-width: 400px;
    left: 50px;
    bottom: 30px;
  }
  @media (max-width: 576px) {
    font-size: 24px;
    width: 100%;
    left: 0;
    bottom: 20px;
    text-align: center;
  }
}





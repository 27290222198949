.root{
  background: #FFFFFF;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.20);
  padding: 6px;
  border-radius: 20px;
  overflow: hidden;
  @media (max-width: 1200px) {

  }

  @media (max-width: 992px) {
  }

  @media (max-width: 768px) {
    border-radius: 15px;
  }

  @media (max-width: 576px) {
    border-radius: 12px;
  }

  @media (max-width: 320px) {
  }
}


.root {
  background-color: #F1C40F;
  color: black;
  padding-top: 60px;
  padding-bottom: 60px;
}

.title {
  font-size: 48px;
  margin-bottom: 0;
}

.caption {
  font-size: 36px;
  line-height: 2.5rem;
}

.field {
  padding-top: 30px;
  padding-bottom: 30px;
}

.img{
  max-width: 42px;
}

@media (max-width: 1200px) {
  .title {
    font-size: 40px;
  }
  .caption {
    font-size: 28px;
    line-height: 2rem;
  }
  .img{
    max-width: 37px;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 36px;
  }
  .caption {
    font-size: 24px;
    line-height: 1.5rem;
  }
  .img{
    max-width: 30px;
  }
}


@media (max-width: 768px) {
  .title {
    font-size: 30px;
  }
  .caption {
    font-size: 24px;
    line-height: 1.5rem;
  }
  .img{
    max-width: 23px;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 24px;
  }
  .caption {
    font-size: 14px;
    line-height: 1rem;
  }
  .img{
    max-width: 16px;
  }
}
.root{

}

.label{
  display: none;
}

.field{
  text-align: center;
  font-size: 2rem;
  line-height: 1.5;
  padding: 0.85rem 2.7rem;
  border: 1px solid white;
  &:focus{
    border: 1px solid white;
  }
  &:active{
    border: 1px solid white;
  }
  &:target{
    border: 1px solid white;
  }
}

@media (max-width: 1200px) {
  .field {
  }
}

@media (max-width: 992px) {
  .field {
  }
}


@media (max-width: 768px) {
  .field {
    font-size: 1.5rem;
    padding: 0.85rem 1.85rem;
  }
}

@media (max-width: 576px) {
  .field {
    font-size: 1rem;
    padding: 0.85rem 0.85rem;
  }
}
.content{
  position: relative;
  margin-bottom: 25px;
}

.name{
  position: absolute;
  z-index: 1;
  padding: 30px 50px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #181717 76.09%);
  h3 {
    font-size: 30px;
  }

}

@media (max-width: 1200px) {
  .name{
    font-size: 24px;
    padding: 30px 30px;
  }

}

@media (max-width: 992px) {
  .name{
    h3 {
      font-size: 20px;
    }
    padding: 30px 20px;
  }
}

@media (max-width: 768px) {
  .name{
    h3 {
      font-size: 20px;
    }
    padding: 30px 20px;
  }
}

@media (max-width: 576px) {
  .name{
    h3 {
      font-size: 16px;
    }
    padding: 30px 20px;
  }
}

@media (max-width: 320px) {
  .name{
    h3 {
      font-size: 12px;
    }
    padding: 20px 20px;
  }
}

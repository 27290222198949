.slider {
    position: relative;
    width: 100%;
    height: 500px;
    overflow: hidden
}
@media (max-width: 1450px) {
    .slider {
        height: 450px;
    }
}

@media (max-width: 1300px) {
    .slider {
        height: 400px;
    }
}

@media (max-width: 1150px) {
    .slider {
        height: 350px;
    }
}

@media (max-width: 1000px) {
    .slider {
        height: 300px;
    }
}

@media (max-width: 800px) {
    .slider {
        height: 250px;
    }
}

@media (max-width: 700px) {
    .slider {
        height: 210px;
    }
}

@media (max-width: 500px) {
    .slider {
        height: 200px;
    }
}

@media (max-width: 480px) {
    .slider {
        height: 180px;
    }
}

@media (max-width: 440px) {
    .slider {
        height: 146px;
    }
}

@media (max-width: 410px) {
    .slider {
        height: 133px;
    }
}

@media (max-width: 320px) {
    .slider {
        height: 114px;
    }
}


.slider a.previousButton, .slider a.nextButton {
    font-size: 22px;
    line-height: 0;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    z-index: 1;
    color: #333;
    padding: 10px;
    text-decoration: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
    /* prevent jump effect when scaling */
}

.slider a.previousButton:not(.disabled):hover, .slider a.nextButton:not(.disabled):hover {
    -webkit-transform: translateY(-50%) scale(1.25);
    transform: translateY(-50%) scale(1.25);
    cursor: pointer;
}

.slider a.previousButton {
    left: 20px;
}

.slider a.previousButton.disabled {
   display: none;
}

.slider a.nextButton {
    right: 20px;
}

.slider a.nextButton.disabled {
    display: none;
}


.slide {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden
}

.slide.hidden {
    visibility: hidden;
}

.slide.previous {
    left: -100%;
}

.slide.current {
    left: 0;
}

.slide.next {
    left: 100%;
}

.slide.animateIn,
.slide.animateOut {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.slide.animateIn.previous,
.slide.animateIn.next {
    left: 0;
    visibility: visible;
}

.slide.animateOut.previous {
    left: 100%;
}

.slide.animateOut.next {
    left: -100%;
}


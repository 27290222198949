.root {
}

.category {
  background: white;
  margin: auto;
  position: relative;
  padding: 1px;

  :before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}

.categoryLogo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
}

.name {
  margin-top: 10px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 16px;
  }

  @media (max-width: 576px) {
  }

  @media (max-width: 320px) {
    font-size: 10px;
    line-height: 12px;
  }
}

.root{
  background-color: white;
}

.logo{
  padding: 17px 50px;
  @media (max-width: 576px) {
    text-align: center;
  }
}

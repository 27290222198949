.root {
  font-size: 20px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 26px 20px;
  border-radius: 3rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  color: #fff;
  border: 1px solid #3366FF;
  background-color: #3366FF;

  &:hover {
    background-color: #0332D1;
    border-color: #0332D1;
  }

  &:active {
    background-color: #0345F6;
    border-color: #0345F6;
  }

  &:disabled {
    background-color: #7b9cff;
    border-color: #7b9cff;
  }

  @media (max-width: 1200px) {
    padding: 26px 20px;
  }

  @media (max-width: 992px) {
    font-size: 16px;
    line-height: 19px;
    padding: 21px 11px;
  }

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;
    padding: 18px 12px;
  }

  @media (max-width: 576px) {
    font-size: 11px;
    line-height: 14px;
    padding: 17px 11px;
  }

  @media (max-width: 320px) {
    padding: 16px 10px;
  }
}

.header{
  text-transform: uppercase;
  font-size: 48px;
  margin-bottom: 30px;
}
.content{
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (max-width: 1200px) {
  .header{
    font-size: 42px;
  }
}

@media (max-width: 992px) {
  .header{
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .header{
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .header{
    font-size: 12px;
  }
}
.root {
  background: #FFFFFF;
  box-shadow: 0 6.25px 31.25px rgba(0, 0, 0, 0.25);
  margin-bottom: 30px;
  padding: 24px 46px;
}

.images {
  float: left;
  width: 100%;
}

.brandLogo {
  float: left;
  width: 20%;
  padding-right: 20px;

  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 576px) {
    width: 23%;
    padding-right: 8px;
  }
  @media (max-width: 320px) {
    padding-right: 5px;
  }
}

.categories {
}

.actions {
  padding-top: 24px;
}

.title {
  font-weight: bold;
  font-size: 36px;
  line-height: 31px;
  color: #222222;
  padding-bottom: 10px;
}

.description {
  font-size: 24px;
  line-height: 31px;
  color: #6D6D72;
}

.button {
  min-width: 230px;
}

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .root {
    padding: 20px 42px;
  }
  .title {
    font-size: 30px;
    line-height: 26px;
  }
  .description {
    font-size: 20px;
    line-height: 31px;
  }
}

@media (max-width: 768px) {
  .root {
    padding: 16px 38px;
  }
  .title {
    font-size: 24px;
    line-height: 20px;
  }
  .description {
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 576px) {
  .root {
    padding: 16px 22px;
  }
  .title {
    font-size: 20px;
    line-height: 20px;
  }
  .description {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 320px) {
  .root {
    padding: 16px 16px;
  }
  .title {
    font-size: 15px;
    line-height: 20px;
  }
  .description {
    font-size: 13px;
    line-height: 16px;
  }
  .button {
    min-width: 160px;
  }
}


.input {
  text-align: center;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  width: 100%;
  border: none;
  border-bottom: 4px solid #181717;

  &:focus {
    outline: none;
    border: none;
    border-bottom: 4px solid #181717;
  }

  &:active {
    border: none;
    border-bottom: 4px solid #181717;
  }
}

.inputGroup {
  padding-left: 200px;
  padding-right: 200px;
  text-align: center;
  padding-bottom: 20px;
}

.inputLabel {
  font-weight: bold;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
  color: #6D6D72;
  margin-bottom: 50px;
}

.modalRoot {
  width: 100%;
  text-align: center;
  padding: 70px 10px;
}

@media (max-width: 1200px) {
  .inputLabel {
    font-size: 35px;
    margin-bottom: 20px;
  }
  .input {
    font-size: 30px;
    line-height: 40px;
  }
}

@media (max-width: 992px) {
  .inputGroup {
    padding-right: 0;
    padding-left: 0;
  }
  .inputLabel {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .input {
    font-size: 18px;
    line-height: 36px;
    border-bottom: 3px solid #181717;
  }
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .inputLabel {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .input {
    font-size: 22px;
    line-height: 30px;
    border-bottom: 2px solid #181717;
  }
}

@media (max-width: 320px) {
  .inputLabel {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .input {
    font-size: 18px;
    line-height: 30px;
  }
}


.link{
  font-weight: 600;
  font-size: 20px;
  line-height: 44px;
  text-decoration: none;
  margin-top: 30px;
}

.root {

  position: relative;

  padding-top: 27px;
  padding-bottom: 27px;
  font-size: 24px;

  @media (max-width: 1200px) {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 24px;
  }

  @media (max-width: 992px) {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 20px;
  }

  @media (max-width: 768px) {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 18px;
  }

  @media (max-width: 576px) {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 16px;
  }

  @media (max-width: 320px) {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
  }
}

.address{
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  font-size: 18px;
  padding: 30px;
}